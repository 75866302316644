<template>
    <div class="con-info">
        <div class="h-100 ns-info" style="min-height:100vh;">
        <SyaratAda/>
        </div>
    </div>
  </template>
  <script>
  import SyaratAda from '@/components/SyaratTsel.vue'
  export default {
    components: {
      // Popup,
      SyaratAda,
    },
    data() {
      return {
        
      };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                document.title = 'Informasi Pelanggang'
            }
        },
    },
    computed: {
      
    },
    mounted() {
      
    },
  };
  </script>
  <style>
  </style>
  <style scoped>
  .con-info{
    background: #cc0e0e;
    padding: 40px;
    margin: 0px;
  }
  .ns-info{
    min-height: 100vh;
    padding: 20px;
    background: #fff;
    border-radius: 19px;
    max-width: 600px;
    margin: auto;
  }
  </style>
  